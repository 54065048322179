import Vue from "vue";
import Vuex from "vuex";

import masterData from "./modules/masterData";
import session from "./modules/session";
import bookingData from "./modules/bookingData";
import availabilityCheck from "./modules/availabilityCheck";
import error from "./modules/error";
import contact from "./modules/contact";
import calculationResults from "./modules/calculationResults"
import rentGapsData from "./modules/rentGapsData"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    clientConfig: require("@/assets/clientConfig.json")
  },
  getters: {
    clientConfig(state) {
      return state.clientConfig;
    }
  },
  modules: {
    masterData,
    session,
    bookingData,
    availabilityCheck,
    error,
    contact,
    calculationResults,
    rentGapsData
  }
});
