<template>
  <div
    class="nxmv-booking-line-outer"
    data-app
  >
    <div class="nxmv-booking-line-inner">
      <div>
        <FontAwesomeIcon icon="car" />
        <div :style="{position: 'relative', marginTop: selectableVariants ? '-17px' : '0'}">
          <v-select
            v-model="selectionFormData.modelId"
            :items="dropdownModels"
            :loading="masterDataLoading"
            :disabled="masterDataLoading || disableModelSelection"
            solo
            flat
            append-icon=""
            style="height: 100%; padding-top: 5px; border-radius: 33px 33px 0px 0px;"
            :label="
              masterDataLoading
                ? $t('vehicleSearchPage.dropdown.modelsLoading')
                : $t('singleLine.chooseModel')
            "
          />
          <v-select
            v-if="selectableVariants"
            v-model="selectionFormData.modelVariantId"
            :items="selectableVariants"
            :loading="masterDataLoading"
            :disabled="masterDataLoading"
            solo
            flat
            append-icon=""
            class="nxmv-sub-menu"
          />
        </div>
      </div>
      <div>
        <FontAwesomeIcon icon="map-marker-alt" />
        <v-select
          v-model="selectionFormData.siteId"
          :items="dropdownSites"
          :loading="masterDataLoading"
          :disabled="masterDataLoading || disableSiteSelection"
          solo
          flat
          append-icon=""
          style="height: 100%; padding-top: 5px;"
          :label="
            masterDataLoading
              ? $t('vehicleSearchPage.dropdown.sitesLoading')
              : $t('singleLine.chooseCity')
          "
        />
      </div>
      <div
        ref="calendarButton"
        @click="toggleCalendar"
      >
        <FontAwesomeIcon icon="calendar-alt" />
        <p
          v-if="selectionFormData.endDate"
          :style="{marginLeft: '15px', color: 'black'}"
        >
          {{ $t('singleLine.from') }} <DateDisplay :date="selectionFormData.startDate" /> <br> {{ $t('singleLine.until') }} <DateDisplay :date="selectionFormData.endDate" />
        </p>
        <p
          v-else
          :style="{marginLeft: '15px', color: 'rgba(0, 0, 0, 0.6)'}"
        >
          {{ $t('singleLine.chooseDate') }}
        </p>
      </div>
      <div @click="navigateForward">
        <p>{{ $t(forwardButtonTextKey) }}</p>
        <p v-if="calculationResults.bookingDays > 0">
          {{ $t('singleLine.priceInfo', [(calculationResults.rentPrice / calculationResults.bookingDays).toFixed(0).replace('.', ',')]) }}
        </p>
      </div>
    </div>
    <div
      ref="calendarDropdown"
      class="nxmv-dropdown calendar-dropdown"
      :style="{left: calendarPosition?.left, top: calendarPosition?.top, display: calendarPosition ? 'block' : 'none'}"
    >
      <BookingCalendar
        :range-data="{
          start: selectionFormData.startDate,
          end: selectionFormData.endDate,
        }"
        @setStartDate="selectionFormData.startDate = $event"
        @setEndDate="selectionFormData.endDate = $event"
      />
    </div>
    <div
      v-for="alert in calculationResults.alerts"
      :key="alert.title"
      class="nxmv-alert"
    >
      <FontAwesomeIcon
        class="nxmv-alert-icon"
        :icon="alert.type === 'warning' ? 'exclamation-triangle' : 'info-circle'"
      />
      <p>
        <span class="nxmv-alert-title">
          {{ $t(alert.title) }}
        </span>
        <span class="nxmv-alert-subtitle">
          {{ $t(alert.subtitle) }}
        </span>
      </p>
    </div>
    <div
      v-if="showModal"
      class="nxmv-modal"
      @click="showModal = false"
    >
      <div
        class="nxmv-close-button"
        @click="showModal = false"
      >
        <FontAwesomeIcon icon="times" />
      </div>
      <div
        ref="modalContent"
        class="nxmv-modal-content"
      >
        <iframe
          style="border: none; width: 100%; height: 3000px;"
          :src="framePath"
        />
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapGetters, mapMutations} from "vuex";
import BookingCalendar from "@/components/ui/Calendar";
import DateDisplay from "@/components/ui/DateDisplay";

export default {
  name: "SingleLine",
  components: {
    BookingCalendar,
    DateDisplay,
  },
  props: {
    modelId: {
      type: String,
      default: undefined,
    },
    siteId: {
      type: String,
      default: undefined,
    },
    disableModelSelection: {
      type: Boolean,
      default: false,
    },
    disableSiteSelection: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    calendarPosition: null,
    showModal: false,
  }),
  mounted() {
    document.addEventListener('click', this.handleClickOutside);
    document.addEventListener('touchstart', this.handleClickOutside);
    window.addEventListener('message', this.handleIframeMessage);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleClickOutside);
    document.removeEventListener('touchstart', this.handleClickOutside);
    window.removeEventListener('message', this.handleIframeMessage);
  },
  computed: {
    ...mapGetters({
      models: "models",
      sites: "sites",
      isAvailable: "isAvailable",
      selectionFormData: "selectionFormData",
      bookingDetails: "bookingDetails",
      calculationResults: "calculationResults",
      masterDataLoading: "masterDataLoading",
      masterDataError: "masterDataError",
      referral: "referral",
    }),
    dropdownModels() {
      var modelNames = new Set();
      const models = this.models.map((x) => {
        if (!modelNames.has(x.name)) {
          modelNames.add(x.name)
          return {text: x.name, value: x.modelId};
        }
      });
      return models.sort((a, b) => (a.text > b.text) ? 1 : -1)
    },
    dropdownSites() {
      return this.sites.map((x) => {
        return {text: x.name, value: x.siteId};
      });
    },
    forwardButtonTextKey() {
      if (!this.selectionFormData.modelId) {
        return "vehicleSearchPage.button.missingModel";
      }
      if (!this.selectionFormData.siteId) {
        return "vehicleSearchPage.button.missingSite";
      }
      if (!this.selectionFormData.startDate) {
        return "vehicleSearchPage.button.missingDate";
      }

      return this.calculationResults.isBookable
        ? "vehicleSearchPage.button.checkAvailability"
        : "vehicleSearchPage.button.toLead";
    },
    selectableVariants() {
      const selectedModel = this.models.find(m => m.modelId === this.selectionFormData.modelId);

      if (selectedModel) {
        const selectableVariants = selectedModel.modelVariants.filter(mv => mv.isSelectable);
        const variants = selectableVariants.map(mv => ({ text: mv.name, value: mv.modelVariantId }));
        return variants.length >= 2 ? [{ text: this.$t('singleLine.chooseVariant') , value: null }, ...variants] : null;
      }

      return null;
    },
    framePath() {
      let path = window.apiEndpoint ? window.apiEndpoint : "";
      path += "/#/booking/search?fullWidth=true&autoSearch=true";
      if (this.selectionFormData.modelId) { path += "&modell=" + this.selectionFormData.modelId; }
      if (this.selectionFormData.modelVariantId) { path += "&variant=" + this.selectionFormData.modelVariantId; }
      if (this.selectionFormData.siteId) { path += "&site=" + this.selectionFormData.siteId; }
      if (this.selectionFormData.startDate) { path += "&startDate=" + this.selectionFormData.startDate; }
      if (this.selectionFormData.endDate) { path += "&endDate=" + this.selectionFormData.endDate; }
      if (this.referral) { path += "&referral=" + this.referral; }
      return path;
    },
  },
  watch: {
    selectionFormData: {
      immediate: true,
      deep: true,
      async handler() {
        // close calendar dropdown if model or site is selected
        this.calendarPosition = this.calendarPosition ? null : this.calendarPosition
        // reset modelVariantId if not present in selectableVariants
        if (this.selectionFormData.modelVariantId &&
          (!this.selectableVariants || !this.selectableVariants.find(mv => mv.value === this.selectionFormData.modelVariantId))) {
          this.selectionFormData.modelVariantId = null;
        }
        // always try to recalculate price when selectionFormData changes
        await this.CALCULATE_PRICE(this.selectionFormData)
      },
    },
  },
  async created() {
    document.documentElement.setAttribute("lang", this.$i18n.locale);

    // If query parameter referral present, pass it to the store
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has("referral")) {
      this.SET_REFERRAL({ referral: urlParams.get("referral") });
    }

    // Set modelId and siteId from data attributes if present
    if (this.modelId) {
      this.SAVE_SELECTION_FORM_DATA({
        modelId: this.modelId,
      });
    }
    if (this.siteId) {
      this.SAVE_SELECTION_FORM_DATA({
        siteId: this.siteId,
      });
    }

    try {
      await this.INITIALIZE_APP();
    } catch (err) {
      this.UPDATE_ERROR({ error: err });
    }
  },
  methods: {
    ...mapActions({
      INITIALIZE_APP: "INITIALIZE_APP",
      SAVE_SELECTION_FORM_DATA: "SAVE_SELECTION_FORM_DATA",
      CHECK_AVAILABILITY: "CHECK_AVAILABILITY",
      UPDATE_CALCULATION_RESULTS: "UPDATE_CALCULATION_RESULTS",
      CALCULATE_PRICE: "CALCULATE_PRICE",
    }),
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
      SET_OFFERS: "SET_OFFERS",
      RESET_SELECTED_PICKUP_TIMES: "RESET_SELECTED_PICKUP_TIMES",
      SET_REFERRAL: "SET_REFERRAL",
    }),
    toggleCalendar(event) {
      this.calendarPosition = this.calendarPosition ? null : {left: event.target.offsetLeft + 'px', top: event.target.offsetTop + 'px'};
    },
    handleClickOutside(event) {
      if (!this.calendarPosition) {
        return;
      }
      if (this.$refs.calendarButton && this.$refs.calendarButton.contains(event.target)) {
        return;
      }
      if (this.$refs.calendarDropdown && !this.$refs.calendarDropdown.contains(event.target)) {
        this.calendarPosition = null;
      }
    },
    handleIframeMessage(event) {
      if (event.data != "scrollToTop")
        return

      if (this.$refs.modalContent) {
        this.$refs.modalContent.scrollTop = 0;
      }
    },
    async navigateForward() {
      if (
        !this.calculationResults.alerts.find((x) => x.type == "error") &&
        !!this.selectionFormData.startDate &&
        !!this.selectionFormData.modelId &&
        !!this.selectionFormData.siteId
      ) {
        this.SAVE_SELECTION_FORM_DATA(this.selectionFormData);
        this.showModal = true;
      }
    },
  },
}
</script>

<style>
.nxmv-dropdown {
    position: absolute;
    min-width: 100px;
    min-height: 100px;
    background-color: rgba(255, 255, 255, 0.1);
    z-index: 9999;
}

.nxmv-booking-line-outer {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    position: relative;
}
.nxmv-booking-line-inner {
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    margin-left: 0;
    border-radius: 33px;
    background-color: rgb(240, 200, 77);
    border: 3px solid #6F817E;
    overflow: hidden;
    display: flex;
}

@media only screen and (max-width: 400px){
  .nxmv-booking-line-inner {
    width: calc(96vw - 2%);
  }
}
@media only screen  and (min-width: 600px) and (max-width: 900px) {
  .nxmv-booking-line-inner {
    margin-left: 5vw;
    width: calc(90vw - 2%);
  }
}

@media only screen and (max-width: 900px) {
    .nxmv-booking-line-inner {
        display: inline-block;
        margin-left: 0;
        width: 100%;
    }

    .nxmv-booking-line-inner > div {
        width: 100% !important;
        height: 25% !important;
    }

    .nxmv-booking-line-inner > div:last-child {
        width: 100% !important;
        height: 25% !important;
    }

    .nxmv-booking-line-inner > div {
        width: 100% !important;
    }

    .nxmv-booking-line-inner > div:first-child {
        border-radius: 33px 33px 0px 0px;
    }

    .nxmv-booking-line-inner > div:last-child {
        border-radius: 0px 0px 33px 33px;
    }

    .nxmv-booking-line-inner > div:not(:last-child) {
        border-bottom: 1px solid black;
        padding-left: 20px;
    }
}

@media only screen and (max-width: 600px) {
  .nxmv-booking-line-inner {
    border-radius: 33px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    height: 280px;
    }
}

@media only screen and (max-width: 900px) and (min-width: 600px) {
  .nxmv-booking-line-inner {
    height: 280px;
    }
}

.nxmv-booking-line-inner > div:not(:last-child) {
    border-right: 1px solid black;
    padding-left: 20px;
}
.nxmv-booking-line-inner > div {
    width: 35%;
    height: 60px;
    box-sizing: border-box;

    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;

    background-color: white;
    cursor: pointer;

    font-size: 16px;

}

.nxmv-booking-line-inner .nxmv-sub-menu {
    position: absolute;
    bottom: 0;
    margin: 0;
    padding: 0;
    left: 0;
    font-style: italic;
    font-size: 13px;
    color: darkgray;
    max-height: 10px;
    height: 10px;
}

.nxmv-sub-menu .v-select__selection {
    width: 100%;
    max-width: 100%;
    color: gray;
    margin: 0;
    padding: 0;
}

.nxmv-sub-menu .v-select__selections {
    margin: 0;
    padding: 0;
    max-height: 10px;
}

.nxmv-sub-menu .v-input__control {
    min-height: 0 !important;
}

.theme--light.v-text-field--solo > .v-input__control > .v-input__slot {
    background: transparent;
}


.nxmv-booking-line-inner > div:last-child {
    width: 40%;
    background-color: #A0C519;
    color: white;
    font-size: 16px;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    padding-right: 0;
}
.nxmv-booking-line-inner>div:last-child p:first-child {
    margin: 0;
    font-weight: bold;
}
.nxmv-booking-line-inner>div:last-child p:nth-child(2) {
    margin-top: 3px;
    margin-bottom: 2px;
    font-size: 13px;
}

.nxmv-booking-line-inner > div > p, svg {
    pointer-events: none;
}
.nxmv-booking-line-inner > div > p {
    margin: 0;
}
.nxmv-booking-line-inner svg {
    font-size: 1.7rem;
    color: #00806B;
    width: 20px;
    margin-right:12px;
}

.nxmv-alert {
    margin-top: 0px;
    background-color: transparent;
    border-radius: unset;
    width: 100%;
    left: 0;

    position: relative;
    padding: 20px;
    box-sizing: border-box;

    display: flex;
    align-content: center;
    align-items: center;
    flex-direction: row;

    cursor: pointer;
    font-size: 1em;
    color:#fff;
}
.nxmv-alert p {
    margin: 0;
}
.nxmv-alert svg {
    font-size: 1.5rem;
    margin: 0 10px;
}
.nxmv-alert p span {
    display: inline;
}
.nxmv-alert span:first-child {
    font-weight: 500;
}

.nxmv-dropdown.calendar-dropdown {
    -webkit-box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
    border-radius: 4px;
    left: 55px;
}
.nxmv-dropdown.calendar-dropdown .vc-container {
    border: 0 none;
}

.nxmv-modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}
.nxmv-modal-content {
    width: calc(100vw - 2%);
    max-width: 800px;
    max-height: calc(100vh - 10%);
    overflow-y: auto;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
    box-sizing: border-box;
    padding-top: 50px;
    position: relative;
}
.nxmv-close-button {
    position: relative;
    top: -42%;
    left: 778px;
    font-size: 2rem;
    cursor: pointer;
    border-radius: 9999px;
    z-index: 1;
}

@media only screen and (max-width: 830px) and (min-width: 630px) {
  .nxmv-close-button {
    left: 94%;
  }
}

@media only screen and (max-width: 630px) and (min-width: 570px) {
  .nxmv-close-button {
    left: 93%;
  }
}

@media only screen and (max-width: 570px) and (min-width: 480px) {
  .nxmv-close-button {
    left: 92%;
  }
}

@media only screen and (max-width: 480px) and (min-width: 380px) {
  .nxmv-close-button {
    left: calc(91% - 1%);
  }
}

@media only screen and (max-width: 380px) and (min-width: 280px) {
  .nxmv-close-button {
    left: calc(90% - 1%);
  }
}

.v-text-field.v-text-field--enclosed .v-text-field__details {
    display: none;
}
.v-input {
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
.v-input__slot {
    margin-bottom: 0px;
    padding: 0px;
}
.v-text-field.v-text-field--solo .v-label {
    top: unset;
}

.v-input__control label {
    font-size: 16px;
}

</style>