import axios from "axios";

async function LOAD_GAPS_DATA({ commit }) {
  const res = await axios.get(`/booking/rentGaps`);
  commit("SET_GAPS_DATA", res.data.rentGaps);
  // axios.post(`/booking/retrieveBooking`);
}


export default {
  LOAD_GAPS_DATA
};
