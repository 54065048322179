<template>
  <v-row class="pl-6 pr-6 pb-0">
    <!-- Location Selection -->
    <v-col 
      class="pt-5 pb-0"
      cols="12"
      sm="4"
      md="3"
      lg="2"
    >
    <v-select class="map-selector"
      v-model="selectedOption"
      @change="emitSelectedOption()"
      :items="selection"
      ref="selectedEl"
      :label="this.label"
      variant="outlined"
      density='default'
      color="green"
      auto
      prepend-inner-icon=fa-map-marker
      >
    </v-select>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from "vuex";
export default {
  components: {
  },
  props: {
    icon: {
      type: String,
      default: '$vuetify.icons.cancel'
    }, 
    selection: {
      type: Array,
    },
  

    errorAction: {
      type: Function,
    },
   

    autoProceed: {
      type: Boolean,
      default: false,
    },
    iconOneName: {
      type: String,
    },
  },
  data() {
    return {
      label: "Standort Auswahl",
      selectedOption: null, 
    };
  },
  watch: {
    autoProceed: {
      immediate: true,
      deep: true,
      async handler() {
        if (this.autoProceed) {
          this.executeForwardAction();
        }
      }
    },
  },
  computed: {
    ...mapGetters({
      captchaSolved: "captchaSolved",
      captchaWebsiteKey: "captchaWebsiteKey",
    }),
    loading() {
      return (
        this.loadingCaptchaInput ||
        this.loadingCaptchaVerification ||
        this.loadingForwardAction
      );
    },
    isForwardButtonEnabled() {
      return !this.loading && this.forwardEnabled;
    },
  },
  methods: {
    ...mapActions({
      POST_CAPTCHA: "POST_CAPTCHA",
    }),
    ...mapMutations({
      UPDATE_ERROR: "UPDATE_ERROR",
    }),
    
    
   
    emitSelectedOption(){
      this.label = ""
      this.$emit('selected-option', this.selectedOption)
    }
  },
};
</script>
<style scoped>

.map-selector{
  border-radius: 7px;
  width:300vw;
}
.chip-container{
 margin: auto;
 margin-left: 0px;

 width: fit-content;
 padding-bottom: 0px;
  
}

</style>

