var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nxmv-booking-line-outer",attrs:{"data-app":""}},[_c('div',{staticClass:"nxmv-booking-line-inner"},[_c('div',[_c('FontAwesomeIcon',{attrs:{"icon":"car"}}),_c('div',{style:({position: 'relative', marginTop: _vm.selectableVariants ? '-17px' : '0'})},[_c('v-select',{staticStyle:{"height":"100%","padding-top":"5px","border-radius":"33px 33px 0px 0px"},attrs:{"items":_vm.dropdownModels,"loading":_vm.masterDataLoading,"disabled":_vm.masterDataLoading || _vm.disableModelSelection,"solo":"","flat":"","append-icon":"","label":_vm.masterDataLoading
              ? _vm.$t('vehicleSearchPage.dropdown.modelsLoading')
              : _vm.$t('singleLine.chooseModel')},model:{value:(_vm.selectionFormData.modelId),callback:function ($$v) {_vm.$set(_vm.selectionFormData, "modelId", $$v)},expression:"selectionFormData.modelId"}}),(_vm.selectableVariants)?_c('v-select',{staticClass:"nxmv-sub-menu",attrs:{"items":_vm.selectableVariants,"loading":_vm.masterDataLoading,"disabled":_vm.masterDataLoading,"solo":"","flat":"","append-icon":""},model:{value:(_vm.selectionFormData.modelVariantId),callback:function ($$v) {_vm.$set(_vm.selectionFormData, "modelVariantId", $$v)},expression:"selectionFormData.modelVariantId"}}):_vm._e()],1)],1),_c('div',[_c('FontAwesomeIcon',{attrs:{"icon":"map-marker-alt"}}),_c('v-select',{staticStyle:{"height":"100%","padding-top":"5px"},attrs:{"items":_vm.dropdownSites,"loading":_vm.masterDataLoading,"disabled":_vm.masterDataLoading || _vm.disableSiteSelection,"solo":"","flat":"","append-icon":"","label":_vm.masterDataLoading
            ? _vm.$t('vehicleSearchPage.dropdown.sitesLoading')
            : _vm.$t('singleLine.chooseCity')},model:{value:(_vm.selectionFormData.siteId),callback:function ($$v) {_vm.$set(_vm.selectionFormData, "siteId", $$v)},expression:"selectionFormData.siteId"}})],1),_c('div',{ref:"calendarButton",on:{"click":_vm.toggleCalendar}},[_c('FontAwesomeIcon',{attrs:{"icon":"calendar-alt"}}),(_vm.selectionFormData.endDate)?_c('p',{style:({marginLeft: '15px', color: 'black'})},[_vm._v(" "+_vm._s(_vm.$t('singleLine.from'))+" "),_c('DateDisplay',{attrs:{"date":_vm.selectionFormData.startDate}}),_vm._v(" "),_c('br'),_vm._v(" "+_vm._s(_vm.$t('singleLine.until'))+" "),_c('DateDisplay',{attrs:{"date":_vm.selectionFormData.endDate}})],1):_c('p',{style:({marginLeft: '15px', color: 'rgba(0, 0, 0, 0.6)'})},[_vm._v(" "+_vm._s(_vm.$t('singleLine.chooseDate'))+" ")])],1),_c('div',{on:{"click":_vm.navigateForward}},[_c('p',[_vm._v(_vm._s(_vm.$t(_vm.forwardButtonTextKey)))]),(_vm.calculationResults.bookingDays > 0)?_c('p',[_vm._v(" "+_vm._s(_vm.$t('singleLine.priceInfo', [(_vm.calculationResults.rentPrice / _vm.calculationResults.bookingDays).toFixed(0).replace('.', ',')]))+" ")]):_vm._e()])]),_c('div',{ref:"calendarDropdown",staticClass:"nxmv-dropdown calendar-dropdown",style:({left: _vm.calendarPosition?.left, top: _vm.calendarPosition?.top, display: _vm.calendarPosition ? 'block' : 'none'})},[_c('BookingCalendar',{attrs:{"range-data":{
        start: _vm.selectionFormData.startDate,
        end: _vm.selectionFormData.endDate,
      }},on:{"setStartDate":function($event){_vm.selectionFormData.startDate = $event},"setEndDate":function($event){_vm.selectionFormData.endDate = $event}}})],1),_vm._l((_vm.calculationResults.alerts),function(alert){return _c('div',{key:alert.title,staticClass:"nxmv-alert"},[_c('FontAwesomeIcon',{staticClass:"nxmv-alert-icon",attrs:{"icon":alert.type === 'warning' ? 'exclamation-triangle' : 'info-circle'}}),_c('p',[_c('span',{staticClass:"nxmv-alert-title"},[_vm._v(" "+_vm._s(_vm.$t(alert.title))+" ")]),_c('span',{staticClass:"nxmv-alert-subtitle"},[_vm._v(" "+_vm._s(_vm.$t(alert.subtitle))+" ")])])],1)}),(_vm.showModal)?_c('div',{staticClass:"nxmv-modal",on:{"click":function($event){_vm.showModal = false}}},[_c('div',{staticClass:"nxmv-close-button",on:{"click":function($event){_vm.showModal = false}}},[_c('FontAwesomeIcon',{attrs:{"icon":"times"}})],1),_c('div',{ref:"modalContent",staticClass:"nxmv-modal-content"},[_c('iframe',{staticStyle:{"border":"none","width":"100%","height":"3000px"},attrs:{"src":_vm.framePath}})])]):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }