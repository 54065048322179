  const SET_GAPS_DATA = (state, value) => {
      state.gaps = value;
    };

  const SET_LAST_GAP_SEEN = (state, value) => {
    if (value) {
      state.lastGapSeen.endDate = value.baseBooking.endDate;
      state.lastGapSeen.equipmentLineId = value.baseBooking.equipmentLineId;
      state.lastGapSeen.modelId = value.baseBooking.modelId;
      state.lastGapSeen.belongsTo = value.baseBooking.belongsTo;
      state.lastGapSeen.modelName = value.baseBooking.modelName;
      state.lastGapSeen.modelVariantId = value.baseBooking.modelVariantId;
      state.lastGapSeen.siteId = value.baseBooking.siteId;
      state.lastGapSeen.siteName = value.baseBooking.siteName;
      state.lastGapSeen.startDate = value.baseBooking.startDate;
      state.lastGapSeen.vehicleId = value.baseBooking.vehicleId;

      state.lastGapSeen.bookingOptionIds = value.bookingDetails.bookingOptionIds;
      state.lastGapSeen.handoverTimeId = value.bookingDetails.handoverTimeId;
      state.lastGapSeen.returnTimeId = value.bookingDetails.returnTimeId;

      state.lastGapSeen.availableHandoverIds = value.pickupTimes.availableHandoverIds;
      state.lastGapSeen.availableReturnIds = value.pickupTimes.availableReturnIds;
      state.lastGapSeen.defaultHandoverTimeId = value.pickupTimes.defaultHandoverTimeId;
      state.lastGapSeen.defaultReturnTimeId = value.pickupTimes.defaultReturnTimeId;
    }
  };

  const RESET_LAST_GAP_SEEN = state => { 
    state.lastGapSeen.endDate = null;
    state.lastGapSeen.equipmentLineId = null;
    state.lastGapSeen.modelId = null;
    state.lastGapSeen.modelName = null;
    state.lastGapSeen.modelName = null;
    state.lastGapSeen.modelVariantId = null;
    state.lastGapSeen.siteId = null;
    state.lastGapSeen.siteName = null;
    state.lastGapSeen.startDate = null;

    state.lastGapSeen.bookingOptionIds = null;
    state.lastGapSeen.handoverTimeId = null;
    state.lastGapSeen.returnTimeId = null;

    state.lastGapSeen.availableHandoverIds = null;
    state.lastGapSeen.availableReturnIds = null;
    state.lastGapSeen.defaultHandoverTimeId = null;
    state.lastGapSeen.defaultReturnTimeId = null;
  };

  export default {
    SET_GAPS_DATA,
    SET_LAST_GAP_SEEN,
    RESET_LAST_GAP_SEEN
  };
