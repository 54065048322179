<template>

  <div>
    <div class="nxmv-model-banner">
      <v-card
        v-if="modelToDisplay"
        hover
        class="mx-auto shape car-item"
        max-width="344"
      >
        <div v-if="rentGap" class="nxmv-model-banner-car-discount">{{ rentGap.discountPercentage}}%</div>
        <div class="car-border">
          <img src="https://nextmove.de/wp-content/uploads/other-images/border.svg" alt="border image" />
        </div>
        <p class="car-title">
          {{getModelName(modelToDisplay.modelId).name}}
        </p>
        <hr class="hr-line">
        <p v-if="rentGap" class="car-text-xs">
          {{rentGap.siteName}}
        </p>
        <p v-if="rentGap" class="car-text">
          <DateDisplay :date="rentGap.startDate" :shortDate=true />
           - 
          <DateDisplay :date="rentGap.endDate" :shortDate=true />
        </p>

        <p v-if="rentGap" class="car-price-number">
          {{ $n(rentGap.rentPrice, "currency") }}
        </p>
        
        <v-avatar
          class="car-img"
          size="250"
          tile
          :class="{ 'nxmv-default': isDefault() }"
        >
        <key-image :model-id="modelToDisplay.modelId" />
        </v-avatar>
      </v-card>

      <v-skeleton-loader
        v-else
        min-width="250"
        min-height="250"
        type="article"
      />
    </div>
  </div>
</template>

<script>
import KeyImage from "./KeyImage";
import DateDisplay from "./DateDisplay";


export default {
  components: {
    KeyImage,
    DateDisplay,

  },

  props: {
    modelToDisplay: { type: Object, default: null},
    openLiveBookingTool: { type: Boolean, default: false },
    rentGap: {type: Object, default: null},
  },
  computed: {
 
  },
  methods: {
    getModelName(modelId) {
      return this.$store.getters.models.find((x) => x.modelId === modelId);
    },
    modelPriceToString(){
      const price = this.$n(Math.ceil(this.modelToDisplay.pricePerDay), "currency")
      return price.replace(/[,\\.]00\s?/g,'');
    },

    // leadButtonClicked() {
    //   // console.log("",this.rentGap.modelId);
    //   if (this.openLiveBookingTool) {
    //     try {
    //       sessionStorage.modelId = this.modelToDisplay.modelId;
    //     } catch (error) {
    //       console.error(error);
    //     }
    //     window.open(this.$t("general.url.bookingTool"), "_parent");
    //   } else {
    //     this.$router.push({
    //       name: "vehicleSearch",
    //       query: { modell: this.rentGap.vehicleid },
    //     });
    //   }
    // },
    img() {
      return "https://nextmove.de/wp-content/uploads/other-images/border.svg";
    },
    isDefault() {
      if (this.rentGap != null) {
        return false;
      }
      else{
        return true;
      }
    },
  },
};
</script>

<style src="@/styles/pages/ModelBanner.scss" lang="scss"></style>

<style scoped>
.nxmv-model-banner-car-discount{
  height: 75px;
  width: 75px;
  background-color: #C32222;
  border-radius: 50% !important;
  position: absolute;
  top: 0px;
  right: 0px;
  padding-top: 23px;
  font-size: x-large;
  font-weight: bold;
  color: white;
  text-align: center;
}
.nxmv-default{
  margin-top: -40px; 
}
</style>