const models = state => state.models;
const sites = state => state.sites;
const captchaWebsiteKey = state => state.captchaWebsiteKey;
const masterDataLoading = state => state.masterDataStatus == "loading";
const masterDataError = state => state.masterDataStatus == "error";

export default {
  models,
  sites,
  captchaWebsiteKey,
  masterDataLoading,
  masterDataError,
};
