import Vue from "vue";
import VueI18n from "vue-i18n";

Vue.use(VueI18n);

export function loadLocaleMessages() {
  const locales = require.context(
    "@/locales",
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );
  const messages = {};
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });
  return messages;
}

const dateTimeFormats = {
  "de-DE": {
    short: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    },
    withDay: {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      weekday: "short"
    }
  },
  "en-US": {
    short: {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
    },
    withDay: {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
      weekday: "short"
    }
  }
};

const numberFormats = {
  "de-DE": {
    currency: {
      style: "currency",
      currency: "EUR"
    }
  },
  "en-US": {
    currency: {
      style: "currency",
      currency: "USD"
    }
  }
};

export default new VueI18n({
  locale: "de-DE",
  fallbackLocale: "de-DE",
  messages: loadLocaleMessages(),
  dateTimeFormats,
  numberFormats
});
