import axios from "axios";
import { addNames } from "../../helpers";

const UPDATE_BOOKING_DETAILS = (context, payload) => {
  if (payload) {
    // remove selected options that are not available any more
    var filteredBookingOptionIds = payload.bookingOptionIds.filter(x =>
      isOptionAvailable(x, context.rootGetters.availableBookingOptions)
    );

    const details = {
      bookingOptionIds: filteredBookingOptionIds,
      handoverTimeId: payload.handoverTimeId,
      returnTimeId: payload.returnTimeId
    };

    // remove additional driver-data when they are too many:
    if (context.getters.additionalDrivers) {
      var newDrivers = [{}, {}, {}];
      var numberOfAdditionalDrivers =
        context.getters.calculationResults.numberOfAdditionalDrivers;
      for (let i = 0; i < numberOfAdditionalDrivers; i++) {
        newDrivers[i] = context.getters.additionalDrivers[i];
      }

      context.commit("SET_ADDITIONAL_DRIVERS", newDrivers);
    }

    context.commit("SET_BOOKING_DETAILS", details);
    context.commit("SET_CUSTOM_NOTE", payload.customNote);
  }
};

const ADD_BOOKING_OPTION = (context, payload) => {
  if (
    isOptionAvailable(payload, context.rootGetters.availableBookingOptions) &&
    !context.getters.bookingDetails.bookingOptionIds.includes(payload)
  ) {
    context.commit("ADD_BOOKING_OPTION_TO_DETAILS", payload);
  }
};

const CLEANUP_SELECTED_BOOKING_OPTIONS = context => {
  var availableOptions = context.rootGetters.availableBookingOptions;
  var bookingDetails = context.rootGetters.bookingDetails;

  if (bookingDetails?.bookingOptionIds) {
    bookingDetails.bookingOptionIds = bookingDetails.bookingOptionIds.filter(
      x => isOptionAvailable(x, availableOptions)
    );
  }

  context.commit("SET_BOOKING_DETAILS", bookingDetails);
};

function isOptionAvailable(optionId, availableOptions) {
  if (availableOptions) {
    for (const availableOption of availableOptions) {
      for (const selectable of availableOption.selectables) {
        if (selectable.bookingOptionId == optionId) {
          return true;
        }
      }
    }
  }

  return false;
}

const UPDATE_BOOKING_DATA = async (context, payload) => {
  if (payload) {
    addNames(payload.baseBooking);
    context.commit("SET_BOOKING_DATA", { baseBooking: payload.baseBooking });
  } else {
    context.commit("RESET_BOOKING_STATE");
  }
};

const SUBMIT_BOOKING = async ({ rootState }, payload) => {
  var discount = false
  if (payload) {
    discount = payload
  }
  const baseBooking = rootState.bookingData.baseBooking ?? {};
  const details = rootState.bookingData.bookingDetails ?? {};
  const contactData = rootState.contact.contactData ?? {};

  var drivers = [];
  for (let i = 0; i < rootState.calculationResults.numberOfAdditionalDrivers; i++) {
    if (rootState.contact.additionalDrivers?.length > i) {
      drivers.push(rootState.contact.additionalDrivers[i]);
    }
  }

  const request = {
    booking: {
      startDate: baseBooking.startDate,
      endDate: baseBooking.endDate,
      siteId: baseBooking.siteId,
      modelId: baseBooking.modelId,
      belongsTo: baseBooking.belongsTo,
      vehicleId: baseBooking.vehicleId,
      modelVariantId: baseBooking.modelVariantId,
      equipmentLineId: baseBooking.equipmentLineId
    },
    bookingDetails: {
      bookingOptionIds: details.bookingOptionIds,
      handoverTimeId: details.handoverTimeId,
      returnTimeId: details.returnTimeId,
      customNote: rootState.contact.customNote
    },
    contact: {
      companyName: contactData.companyName,
      firstname: contactData.firstname,
      lastname: contactData.lastname,
      email: contactData.email,
      phone: contactData.phone,
      birthday: contactData.birthday,
      street: contactData.street,
      houseNumber: contactData.houseNumber,
      postalcode: contactData.postalcode,
      city: contactData.city,
      countryCode: contactData.countryCode
    },
    additionalDrivers: drivers,
    discount: discount,
  };

  const result = await axios.post(`/booking/submitBooking`, request, { isUncancelable: true });
  return result.data.orderName;
};

const SUBMIT_LEAD = async ({ rootState }) => {
  const selectionFormData = rootState.availabilityCheck.selectionFormData ?? {};
  const contactData = rootState.contact.contactData ?? {};

  const request = {
    startDate: selectionFormData.startDate,
    endDate: selectionFormData.endDate,
    siteId: selectionFormData.siteId,
    modelId: selectionFormData.modelId,
    customNote: rootState.contact.customNote,

    contact: {
      companyName: contactData.companyName,
      firstname: contactData.firstname,
      lastname: contactData.lastname,
      email: contactData.email,
      phone: contactData.phone,
      birthday: contactData.birthday,
      street: contactData.street,
      houseNumber: contactData.houseNumber,
      postalcode: contactData.postalcode,
      city: contactData.city,
      countryCode: contactData.countryCode
    }
  };

  await axios.post(`/lead/submitLead`, request, { isUncancelable: true });
};

export default {
  SUBMIT_BOOKING,
  UPDATE_BOOKING_DATA,
  SUBMIT_LEAD,
  UPDATE_BOOKING_DETAILS,
  CLEANUP_SELECTED_BOOKING_OPTIONS,
  ADD_BOOKING_OPTION
};
