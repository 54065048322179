const SET_MASTER_DATA = (state, value) => {
  state.models = value.models;
  state.sites = value.sites;
  state.captchaWebsiteKey = value.captchaWebsiteKey;
  state.masterDataStatus = "success"
};

const SET_MASTER_DATA_LOADING = (state) => {
  state.masterDataStatus = "loading";
}

const SET_MASTER_DATA_ERROR = (state) => {
  state.masterDataStatus = "error";
}

export default {
  SET_MASTER_DATA,
  SET_MASTER_DATA_LOADING,
  SET_MASTER_DATA_ERROR
};
