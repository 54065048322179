export default {
  state: {
    headline: null,
    error: null,
    buttonRoute: null
  },
  mutations: {
    UPDATE_ERROR: (state, { headline, error, buttonRoute }) => {
      let errorData = error?.response?.data ?? error;

      if (errorData.field && errorData.error) {
        state.field = errorData.field;
        state.error = errorData.error;
      } else {
        state.field = "";
        state.error = errorData;
      }

      state.headline = headline;
      state.buttonRoute = buttonRoute;
    }
  }
};
