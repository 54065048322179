import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const state = {
  models: [],
  sites: [],
  captchaWebsiteKey: "missingKey",
  masterDataStatus: null
};

export default {
  namespaced: false,
  state,
  actions,
  getters,
  mutations
};
