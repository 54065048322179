import axios from "axios";
import { addNames, getVehicleMasterData } from "../../helpers";

const CHECK_AVAILABILITY = async ({ dispatch, commit, state }, payload) => {
  let data = {
    booking: {
      belongsTo: state.selectionFormData.belongsTo,
      startDate: state.selectionFormData.startDate,
      endDate: state.selectionFormData.endDate,
      siteId: state.selectionFormData.siteId,
            modelId: state.selectionFormData.modelId,
      modelVariantId: state.selectionFormData.modelVariantId,
      equipmentLineId: state.selectionFormData.equipmentLineId
    },
    bookingDetails: {
      handoverTimeId: null,
      returnTimeId: null
    },
    weighting: getWeighting(state.selectionInputHistory),
    discount: payload
  };

  const res = await axios.post(`/vehicleSearch/checkAvailability`, data);

  res.data.bookings.forEach(b => addNames(b.baseBooking));

  if (res.data.isAvailable) {
    dispatch("UPDATE_BOOKING_DATA", res.data.bookings[0]);
    commit("SET_OFFERS", null);
    commit("SET_AVAILABLE_PICKUP_TIMES", res.data.bookings[0].pickupTimes)
  } else {
    dispatch("UPDATE_BOOKING_DATA", null);
    commit("SET_OFFERS", res.data.bookings);
  }

  commit("SET_AVAILABILITY", res.data.isAvailable);
  commit("SET_ANY_CAR", res.data.anyCarWanted);
};

const SAVE_SELECTION_FORM_DATA = (context, selectionFormData) => {
  if (selectionFormData) {
    var selectionDataToSave = {};
    Object.assign(selectionDataToSave, selectionFormData);
    var masterData = getVehicleMasterData(selectionDataToSave.modelId, selectionDataToSave.modelVariantId, selectionDataToSave.equipmentLineId);
    if (!masterData.modelVariant?.isSelectable) {
      selectionDataToSave.modelVariantId = null;
    }
    if (!masterData.equipmentLine?.isSelectable) {
      selectionDataToSave.equipmentLineId = null;
    }

    addNames(selectionDataToSave);
  }

  context.commit("SET_SELECTION_FORM_DATA", selectionDataToSave);
};

function getWeighting(inputHistory) {
  const totalChanges =
    inputHistory.models.length +
    inputHistory.sites.length +
    inputHistory.dates.length;

  const vehicleWeight =
    (totalChanges / inputHistory.models.length).toFixed(1) * 10;
  const locationWeight =
    (totalChanges / inputHistory.sites.length).toFixed(1) * 10;
  const dateWeight = (totalChanges / inputHistory.dates.length).toFixed(1) * 10;

  return {
    vehicle: vehicleWeight,
    location: locationWeight,
    date: dateWeight
  };
}

export default {
  CHECK_AVAILABILITY,
  SAVE_SELECTION_FORM_DATA
};
