import store from "./store";

function addNames(baseBooking) {
  const masterData = getVehicleMasterData(
    baseBooking.modelId,
    baseBooking.modelVariantId,
    baseBooking.equipmentLineId
  );

  baseBooking.modelName = masterData?.model?.name;
  baseBooking.modelVariantName = masterData?.modelVariant?.name;
  baseBooking.equipmentLineName = masterData?.equipmentLine?.name;

  const site = store.getters.sites.filter(
    item => item.siteId === baseBooking.siteId
  );
  if (site && site.length > 0) {
    baseBooking.siteName = site[0].name;
  }
}

function getVehicleMasterData(modelId, modelVariantId, equipmentLineId) {
  var result = {
    model: null,
    modelVariant: null,
    equipmentLine: null
  };

  const model = store.getters.models.filter(
    item => item.modelId === modelId
  );

  if (model && model.length > 0) {
    result.model = model[0];

    const modelVariant = result.model.modelVariants.filter(
      item => item.modelVariantId === modelVariantId
    );
    if (modelVariant && modelVariant.length > 0) {
      result.modelVariant = modelVariant[0];
    }

    const equipmentLine = result.model.equipmentLines.filter(
      item => item.equipmentLineId === equipmentLineId
    );
    if (equipmentLine && equipmentLine.length > 0) {
      result.equipmentLine = equipmentLine[0];
    }
  }

  return result;
}

export { addNames, getVehicleMasterData };
