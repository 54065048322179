import Vue from "vue";
import Vuetify, {  } from "vuetify/lib";
import "font-awesome/css/font-awesome.min.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
        light: {
          primary: '#00897b'
        }
    }
  },
  icons: {
    iconfont: "fa4",
    values: {
      cancel: "fas fa-ban",
      menu: "fas fa-ellipsis-v"
    }
  }
});
