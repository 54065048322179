import axios from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";

let cancel = [];

// Initialize session-header and load master-data
const INITIALIZE_APP = async ({ rootState, commit }) => {
  initializeSessionHeader(rootState, commit);
  initCancelRunningRequestInterceptor();
  
  commit("SET_MASTER_DATA_LOADING");
  await loadMasterDataWithRetry(commit);
};

function initializeSessionHeader(store, commit) {
  axios.interceptors.request.use(request => {
    request.headers["X-API-Token"] = store.session.token;
    return request;
  });

  const refreshSessionLogic = () =>
    axios
      .post("/session/start", { referral: store.session.referral })
      .then(response => {
        commit("UPDATE_SESSION", response.data);
        return Promise.resolve();
      });
  createAuthRefreshInterceptor(axios, refreshSessionLogic);
}

function initCancelRunningRequestInterceptor() {
  axios.interceptors.request.use((config) => {
    if (cancel[config.url]) {
      cancel[config.url](); // cancel previous request
    }

    if (!config.isUncancelable) {
      config.cancelToken =  new axios.CancelToken(function executor(c) { cancel[config.url] = c; })      
    }
    return config  
  }, function (error) {
    return Promise.reject(error)
  });
}

async function loadMasterDataWithRetry(commit) {
  try {
    const res = await axios.get(`/session/masterData`);
    commit("SET_MASTER_DATA", res.data);
  } catch (err) {
    commit("SET_MASTER_DATA_ERROR");
    setTimeout(() => loadMasterDataWithRetry(commit), 10000);
    throw err;
  }  
}

export default {
  INITIALIZE_APP
};
