<template>
  <div> 

  <v-sheet

    class="nxmv-model-banner-sheet nxmv-model-banner-transparent"
  >
  <FilterWithChips v-if="this.searchedSiteId == null"
    class="nxmv-model-banner-filter"
    @selected-option="handleSelectedOption"
    :selection="getSiteNames()"
  />
 
  <v-slide-group 
      ref="slideGroup"
      
      class="ma-10 nxmv-model-banner-transparent"
      mobile-breakpoint ="md"
      v-if="this.searchedSiteId != null || this.searchedModelId != null && sortedGaps().length != 0"
    >
      <div 
        v-for="gap in sortedGaps()"
        :key="gap.vehicleid"
        @click="selectGap(gap)"
        >
          <ModelBanner  :model-to-display="car(gap.modelId)"  :rent-gap="gap" />
      </div>
    </v-slide-group>
  </v-sheet>
  <!-- <v-btn @click="scrollToItem()">Scroll to Item 2</v-btn> -->

  <div
      v-if="showModal"
      class="nxmv-modal"
      @click="showModal = false"
    >
      <div
        class="nxmv-close-button"
        @click="showModal = false"
      >
        <FontAwesomeIcon icon="times" />
      </div>
      <div
        ref="modalContent"
        class="nxmv-modal-content"
      >
        <iframe
          style="border: none; width: 100%; height: 3000px;"
          :src="this.selectedPath"
        />
      </div>
    </div>
</div>
</template>

<script>
import ModelBanner from "@/components/ui/ModelBanner.vue";
import {mapActions, mapGetters, mapMutations} from "vuex";
import FilterWithChips from "@/components/ui/FilterWithChips"
export default {
  name: "EmbedModelBanner",
  components: {
    ModelBanner,
    FilterWithChips,
  },

  data: () => ({
    selectedLocation: "",
    availableLocations: {},
    showAllLocations: false,
 
    startDate: null,
    endDate: null,
    showModal: false,
    selectedPath: "",
    searchedModelId: null,
    searchedSiteId: null,
  
  }),
  mounted() {
    // document.addEventListener('click', this.handleClickOutside);
    // document.addEventListener('touchstart', this.handleClickOutside);
    window.addEventListener('message', this.handleIframeMessage);
  },
  beforeUnmount() {
    // document.removeEventListener('click', this.handleClickOutside);
    // document.removeEventListener('touchstart', this.handleClickOutside);
    window.removeEventListener('message', this.handleIframeMessage);
  },

  props: {
    siteId: {
      type: String,
      default: null,
    },
    modelId: {
      type: String,
      default: null,
    },
  
  },
  computed: {
    ...mapGetters({
      gaps: "gaps",
      sites: "sites",
      models: "models",
      isAvailable: "isAvailable",
      captchaWebsiteKey: "captchaWebsiteKey",
    }),
  }, 

  async beforeMount() {
 
    this.searchedSiteId = this.siteId;
    await this.LOAD_GAPS_DATA();
    this.availableLocations = this.sortedGaps();
    this.readQueryParameters();
  },
  async created() {
    document.documentElement.setAttribute("lang", this.$i18n.locale);
    try {
      await this.INITIALIZE_APP();
    } catch (err) {
      this.UPDATE_ERROR({ error: err });
    }
  },
methods: {
  ...mapActions({
      LOAD_GAPS_DATA: "LOAD_GAPS_DATA",
      POST_CAPTCHA: "POST_CAPTCHA",
      INITIALIZE_APP: "INITIALIZE_APP",
    }),
  ...mapMutations({
    SET_LAST_GAP_SEEN: "SET_LAST_GAP_SEEN",
  }),

  car(modelId) {
    return this.$store.getters.models.find((x) => x.modelId === modelId);
  },
  
  sortedGaps() {
      let gapArray = new Array();
      this.gaps.forEach((gap) => {
        if (this.siteId != null){ // looked using siteId
          if (gap.siteName == this.selectedLocation){
            gapArray.push(gap);
            gapArray.sort((a,b) => {
              if (a.startDate < b.startDate) {
                return -1
              }
              if (a.startDate > b.startDate) {
                return 1
              }
              return 0
              })
            }
        }
        if(this.searchedModelId != null){
          if((gap.siteName == this.selectedLocation || this.showAllLocations) && gap.modelId == this.searchedModelId){
            gapArray.push(gap);
            gapArray.sort((a,b) => {
              if (a.startDate < b.startDate) {
                return -1
              }
              if (a.startDate > b.startDate) {
                return 1
              }
              return 0
              })
            }
          }
    
      });
      return gapArray;
    },
    getSiteNames() {
      let siteArray = [];
      siteArray.push(this.$t('rentGapsPage.showAll'));
      this.sites.forEach((site) => {
        siteArray.push(this.outputSiteName(site.siteId));
      });
      return siteArray.sort();
    },

    handleSelectedOption(payload) {
      this.selectedLocation = payload;
      if (this.selectedLocation == this.getSiteNames()[0]){
        this.showAllLocations = true;
      }else{
        this.showAllLocations = false;
      }
      this.availableLocations = this.sortedGaps()

      // Resets the slide group to the fist element
      const slideGroup = this.$refs.slideGroup;
      slideGroup.scrollOffset = 0;
    },
    outputSiteName(siteId) {
      return this.sites.find((elem) => elem.siteId === siteId)?.name;
    },

    scrollToItem() {
      const slideGroup = this.$refs.slideGroup;
      slideGroup.scrollOffset = 0;
    },
    handleIframeMessage(event) {
      if (event.data != "scrollToTop")
        return

      if (this.$refs.modalContent) {
        this.$refs.modalContent.scrollTop = 0;
      }
    },
    
    selectGap(gap){
      console.log("Clicked Model ID: ",gap.modelId);
      let path = window.apiEndpoint ? window.apiEndpoint : "";
      path += "/#/booking/details?fullWidth=true";
      if (gap.modelId) { path += "&modell=" + gap.modelId; }
      if (gap.modelVariantId) { path += "&variant=" + gap.modelVariantId; }
      if (gap.siteId) { path += "&site=" + gap.siteId; }
      if (gap.startDate) { path += "&startDate=" + gap.startDate; }
      if (gap.endDate) { path += "&endDate=" + gap.endDate; }
      // if (this.referral) { path += "&referral=" + this.referral; }
      this.selectedPath = path;
      this.showModal = true;
    },
    readQueryParameters(){
      if (this.siteId) {
      this.selectedLocation = this.outputSiteName(this.siteId);
      this.searchedSiteId = this.siteId;
      }
      if(this.modelId) {
        this.showAllLocations = true;
        this.searchedModelId = this.modelId;
      }
    },
  },

}
</script>

<style scoped>

.nxmv-model-banner-sheet {
  width: 100%; /* Fill the parent's width */
  background-color: rgba(255, 255, 255, 0); /* Transparent background */
  justify-content: center; /* or align-items based on layout */
}

.nxmv-model-banner-filter{
  margin-left: 20px;
}

.nxmv-model-banner-transparent{
  background-color: rgba(255, 255, 255, 0);
  justify-content: center;
}

.nxmv-place-holder{
  width: fit-content;
}
</style>