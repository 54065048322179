export default () => ({
  selectionFormData: {
    endDate: null,
    equipmentLineId: null,
    modelId: null,
    modelName: null,
    modelVariantId: null,
    siteId: null,
    siteName: null,
    startDate: null
  },
  isAvailable: null,
  anyCarWanted: null,  
  pickupTimes: {
    availableHandoverIds: [],
    availableReturnIds: [],
    defaultHandoverTimeId: null,
    defaultReturnTimeId: null
  },
  offers: null,
  selectionInputHistory: {
    models: [],
    sites: [],
    dates: []
  }
});
