import defaultState from "./state";

const SET_BOOKING_DATA = (state, value) => {
  if (value) {
    state.baseBooking = value.baseBooking;
  }
};

const SET_BOOKING_DETAILS = (state, value) => {
  state.bookingDetails = value;
};

const RESET_BOOKING_STATE = state => {
  Object.assign(state, defaultState());
};

const RESET_SELECTED_PICKUP_TIMES = state => {
  state.bookingDetails.handoverTimeId = defaultState().bookingDetails.handoverTimeId;
  state.bookingDetails.returnTimeId = defaultState().bookingDetails.returnTimeId;
};

const ADD_BOOKING_OPTION_TO_DETAILS = (state, value) => {
  state.bookingDetails.bookingOptionIds.push(value)
}

export default {
  SET_BOOKING_DATA,
  SET_BOOKING_DETAILS,
  RESET_BOOKING_STATE,
  RESET_SELECTED_PICKUP_TIMES,
  ADD_BOOKING_OPTION_TO_DETAILS
};
