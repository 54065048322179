<template>
  <v-avatar
    class="ma-0"
    height="auto"
    width="250"
    tile
    style="margin-top: 25px;"
  >
    <v-img
    cover
      :src="img"
      contain
      eager
    />
  </v-avatar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KeyImage",

  props: {
    modelId: {
      type: String,
    },
  },

  computed: {
    ...mapGetters({
      models: "models",
    }),
    img() {
      const model = this.models.find((model) => model.modelId === this.modelId);
      return model?.keyImageURL
        ? model.keyImageURL
        // : require("@/assets/automittuch.png");
        : "https://nextmove.de/wp-content/uploads/other-images/automittuch.png";
    },
  },
};
</script>