const selectionFormData = state => state.selectionFormData;
const isAvailable = state => state.isAvailable;
const anyCarWanted = state => state.anyCarWanted;
const offers = state => state.offers;
const pickupTimes = state => state.pickupTimes;

export default {
  selectionFormData,
  isAvailable,
  anyCarWanted,
  offers,
  pickupTimes
};
