const getDefaultState = () => {
  return {
    contactData: null,
    additionalDrivers: null,
    customNote: null
  }
}

export default {
  state: getDefaultState(),
  mutations: {
    SET_CONTACT_DATA: (state, value) => {
      state.contactData = value;
    },
    SET_ADDITIONAL_DRIVERS: (state, value) => {
      state.additionalDrivers = value;
    },
    SET_CUSTOM_NOTE: (state, value) => {
      state.customNote = value;
    },
    RESET_CONTACT_STATE: state => {
      Object.assign(state, getDefaultState());
    }
  },
  getters: {
    contactData: state => state.contactData,
    additionalDrivers: state => state.additionalDrivers,
    customNote: state => state.customNote
  }
};
