<template>
  <vc-date-picker
    v-model="range"
    :locale="$i18n.locale"
    :first-day-of-week="2"
    :attributes="attributes"
    is-range
    :color="color"
    is-inline
    is-required
    :model-config="modelConfig"
    :columns="$screens({ default: 1, sm: 2 })"
    :available-dates="availableDates"
    timezone="Europe/Berlin"
    :from-page="calendarPageToDisplay"
    @update:from-page="(val) => (selectedCalendarPage = val)"
  />
</template>

<script>
export default {
  props: {
    method: { type: Function },
    data: { type: Boolean },
    rangeData: { type: Object },
    fullWidth: { type: Boolean, default: false }
  },
  data() {
    return {
      attributes: [
        {
          key: "today",
          highlight: {
            color: "grey",
            fillMode: "light",
            contentClass: "italic",
          },
          dates: {
            weekdays: [1, 7],
          },
        },
      ],
      range: this.rangeData,
      color: "green",
      modelConfig: {
        type: "string",
        mask: "YYYY-MM-DD",
      },
      tomorrow: new Date(new Date().getTime() + 24 * 60 * 60 * 1000),
    };
  },
  computed: {
    availableDates() {
      return {
        start: this.tomorrow,
        end: new Date(new Date().setFullYear(new Date().getFullYear() + 2)),
      };
    },
    calendarPageToDisplay() {
      const dateForFirstPage = this.range.start
        ? new Date(this.range.start)
        : this.tomorrow;
      return {
        month: dateForFirstPage.getMonth() + 1,
        year: dateForFirstPage.getYear() + 1900,
      };
    },
  },
  watch: {
    rangeData(newVal) {
      this.range = { start: newVal.start, end: newVal.end };
    },
    range(newVal) {
      if (newVal) {
        this.$emit("setStartDate", newVal.start);
        this.$emit("setEndDate", newVal.end);
      }
    },
  },
};
</script>

<style>

.vc-day-content.is-disabled {
    color: #cbd5e0 !important;
}

</style>