import actions from "./actions"
import getters from "./getters";
import mutations from "./mutations";

const state = {
    gaps: [],
    lastGapSeen: {
        endDate: null,
        equipmentLineId: null,
        modelId: null,
        belongsTo: null,
        modelName: null,
        modelVariantId: null,
        vehicleId: null,
        siteId: null,
        siteName: null,
        startDate: null,
        
        bookingOptionIds: null,
        handoverTimeId: null,
        returnTimeId: null,
  
        availableHandoverIds: null,
        availableReturnIds: null,
        defaultHandoverTimeId: null,
        defaultReturnTimeId: null,
    },
};

export default {
    namespaced: false,
    state,
    actions,
    getters,
    mutations
  };