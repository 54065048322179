import axios from "axios";

const defaultState = () => ({
  bookingDays: 0,
  rentPrice: 0,
  optionsPrice: 0,
  totalPrice: 0,
  includedKms: 0,
  numberOfAdditionalDrivers: 0,
  pricePerAdditionalKm: 0,
  availableBookingOptions: [],
  deductible: null,
  deposit: null,
  isLoading: false,
  isBookable: false,
  alerts: [],
});

export default {
  state: defaultState(),
  actions: {
    UPDATE_CALCULATION_RESULTS: async ({ commit, dispatch }, payload) => {
      commit("SET_CALCULATION_RESULTS", payload);
      dispatch("CLEANUP_SELECTED_BOOKING_OPTIONS");
    },
    CALCULATE_PRICE: async (context, selectionFormData) => {
      function getCalcPriceData() { // TODO: Store should just be in this format
        let data = {
          booking: {
            startDate: selectionFormData.startDate,
            endDate: selectionFormData.endDate,
            siteId: selectionFormData.siteId,
            modelName: selectionFormData.modelName,
            modelId: selectionFormData.modelId,
            vehicleId: context.rootState.bookingData.vehicleId,
          },
          bookingDetails: {
            handoverTimeId: context.rootState.bookingData.bookingDetails.handoverTimeId,
            returnTimeId: context.rootState.bookingData.bookingDetails.returnTimeId,
          },
        };

        if (selectionFormData.modelVariantId) {
          data.booking.modelVariantId = selectionFormData.modelVariantId;
        }

        if (selectionFormData.equipmentLineId) {
          data.booking.equipmentLineId = selectionFormData.equipmentLineId;
        }
        return data;
      }

      context.commit("SET_SELECTION_FORM_DATA", selectionFormData);

      // If all required data is available, send calcPrice request to the backend
      if (
        selectionFormData.modelId &&
        selectionFormData.siteId &&
        selectionFormData.startDate &&
        selectionFormData.endDate
      ) {
        try {
          context.commit("IS_LOADING", true);

          let res = await axios.post(`/booking/calcPrice`, getCalcPriceData());

          if (res.data.alerts?.filter((x) => x.type != "info").length > 0) {
            // something's wrong, try again with default handover-times
            context.dispatch("RESET_SELECTED_PICKUP_TIMES");
            res = await axios.post(`/booking/calcPrice`, getCalcPriceData());
          }
          context.dispatch("UPDATE_CALCULATION_RESULTS", res.data);
          context.commit("IS_LOADING", false);

        } catch (err) {
          if (!axios.isCancel(err)) {
            context.commit("IS_LOADING", false);
            context.commit("SET_ERROR", {error: err});
          }
        }
      }
    },
  },
  mutations: {
    SET_CALCULATION_RESULTS: (state, value) => {
      state.bookingDays = value.bookingDays;
      state.rentPrice = value.rentPrice;
      state.monthlyRentPrice = value.monthlyRentPrice;
      state.optionsPrice = value.bookingOptionsPrice;
      state.monthlyOptionsPrice = value.monthlyBookingOptionsPrice;
      state.totalPrice =
        (state.rentPrice ? state.rentPrice : 0) +
        (state.optionsPrice ? state.optionsPrice : 0);
      state.totalMonthlyPrice = 
      (state.monthlyRentPrice ? state.monthlyRentPrice : 0) +
      (value.monthlyBookingOptionsPrice ?  value.monthlyBookingOptionsPrice : 0);
      state.includedKms = value.includedKms;
      state.availableBookingOptions = value.availableBookingOptions;
      state.numberOfAdditionalDrivers = value.additionalDrivers;
      state.pricePerAdditionalKm = value.pricePerAdditionalKm;
      state.deductible = value.deductible;
      state.deposit = value.deposit;
      state.alerts = value.alerts;
      state.isBookable = value.isBookable;
    },
    RESET_CALCULATION_RESULT_STATE: state => {
      Object.assign(state, defaultState());
    },
    IS_LOADING: (state, value) => {
      state.isLoading = value;
    },
  },
  getters: {
    calculationResults: state => state,
    availableBookingOptions: state => state.availableBookingOptions
  }
};
