import defaultState from "./state";

const SET_SELECTION_FORM_DATA = (state, value) => {
  Object.assign(state.selectionFormData, value);

  if (!state.selectionInputHistory.models.includes(value.modelId)) {
    state.selectionInputHistory.models.push(value.modelId);
  }

  if (!state.selectionInputHistory.sites.includes(value.siteId)) {
    state.selectionInputHistory.sites.push(value.siteId);
  }

  if (
    !state.selectionInputHistory.dates.includes(value.startDate + value.endDate)
  ) {
    state.selectionInputHistory.dates.push(value.startDate + value.endDate);
  }
};

const SET_AVAILABILITY = (state, value) => {
  state.isAvailable = value;
};
const SET_ANY_CAR = (state, value) => {
  state.anyCarWanted = value;
};

const SET_OFFERS = (state, value) => {
  state.offers = value;
};

const SET_AVAILABLE_PICKUP_TIMES = (state, value) => {
  state.pickupTimes = value;
}

const RESET_AVAILABILITY_STATE = state => {
  Object.assign(state, defaultState());
};
const RESET_ANY_CAR_STATE = state => {
  Object.assign(state, defaultState());
};

export default {
  SET_SELECTION_FORM_DATA,
  SET_AVAILABILITY,
  SET_ANY_CAR,
  SET_OFFERS,
  SET_AVAILABLE_PICKUP_TIMES,
  RESET_AVAILABILITY_STATE,
  RESET_ANY_CAR_STATE,
};
