import axios from "axios";

export default {
  state: {
    token: null,
    captchaSolved: false,
    fullWidth: false,
    submissionSuccessfull: false,
    referral: null
  },
  mutations: {
    UPDATE_SESSION: (state, value) => {
      const devToken = process.env.VUE_APP_DEV_SESSION_TOKEN;
      if (devToken) {
        state.token = devToken;
        state.captchaSolved = true;
      } else {
        state.token = value.token;
        state.captchaSolved = false;
      }
    },
    SET_CAPTCHA: (state, value) => (state.captchaSolved = value),
    SET_FULL_WIDTH: state => (state.fullWidth = true),
    SET_SUBMISSION_SUCCESS: (state, value) => (state.submissionSuccessfull = value),
    SET_REFERRAL: (state, value) => (state.referral = value.referral)
  },
  actions: {
    POST_CAPTCHA: async ({ commit }, payload) => {
      const response = await axios.post(`/session/captcha`, { responseToken: payload }, { isUncancelable: true });
      const isCaptcha = response.status ? response.status === 200 : false;
      commit("SET_CAPTCHA", isCaptcha);
      return isCaptcha;
    }
  },
  getters: {
    token: state => state.token,
    captchaSolved: state => state.captchaSolved,
    fullWidth: state => state.fullWidth,
    submissionSuccessfull: state => state.submissionSuccessfull,
    referral: state => state.referral
  }
};
